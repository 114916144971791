<template>
  <div class="container-fluid me-3">
    <back-button @click="goBack" />
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
        <div class="row mt-3">
          <div class="col-xl-6 order-xl-1">
            <transition name="el-zoom-in-center">
              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-md-8">
                    <h3 class="mb-0">Set up the profile of the sponsor</h3>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        label="Name *"
                        name="Name"
                        type="text"
                        placeholder="name of the sponsor"
                        v-model="sponsor.name"
                        required
                      />
                      <validation-error
                        :errors="apiValidationErrors.name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        label="Label  *"
                        name="Label"
                        type="text"
                        placeholder="label of the sponsor"
                        v-model="sponsor.label"
                        required
                      />
                      <validation-error
                        :errors="apiValidationErrors.label"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        label="Email *"
                        name="Email"
                        type="text"
                        placeholder="email of the sponsor"
                        v-model="sponsor.email"
                        required
                      />
                      <validation-error
                        :errors="apiValidationErrors.name"
                      />
                    </div>
                  </div>
                  <base-input
                    type="text"
                    label="Summary *"
                    success-message="With bio you can go into a bit more detail about your background."
                  >
                    <textarea
                      v-model="sponsor.summary"
                      placeholder="With bio you can go into a bit more detail about your background."
                      class="form-control"
                      rows="3"
                      name="Summary"
                      required
                    >
                    </textarea>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.summary" />
                  <!-- <template>
                    <base-file-input
                      help-message="<i class='fa fa-upload'></i> Select your Logo to upload! </br> <small class='filepond--label-action text-muted'>Drop or Browse files</small></br> "
                      preview-height="400"
                    />

                    
                  </template> -->
                  
         
                </div>
              </card>
            </transition>
          </div>
           <div class="col-xl-6 order-xl-1">
            <card header-classes="bg-transparent" name="details">
              <div slot="header" class="row align-items-center">
                <div class="col-md-8">
                  <h3 class="mb-0">Provide more details</h3>
                </div>
              </div>
              <div class="card-body">
                <base-input label="Available for">
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.virtual"
                    :description="{
                      show: true,
                      content: 'Virtual events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.physical"
                    :description="{
                      show: true,
                      content: 'In-person events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.hybrid"
                    :description="{
                      show: true,
                      content: 'Hybrid events',
                    }"
                  ></base-switch>
                </base-input>

                <base-input label="Categories">
                  <el-select
                    v-model="sponsor.sponsor_categories"
                    multiple
                    placeholder="Select..."
                  >
                    <el-option
                      v-for="single_category in allCategories"
                      :key="single_category.id"
                      :value="single_category.id"
                      :label="single_category.name"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </card>
            <!-- Sticky buttons -->
            <div id="sticky-buttons" class="my-4 text-right">
              <card type="frame">
                <div class="mt--3 mb--3">
                  <base-button
                    type="button"
                    class="btn btn-primary"
                    v-if="!loading"
                    data-testid="btn-create-item"
                    native-type="submit"
                  >
                    <i class="fa fa-save" /> Create sponsor
                  </base-button>
                  <div class="text-center" v-if="loading">
                    <slot name="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                    </slot>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import moment from "moment-timezone";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option, Tabs, TabPane } from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  name: "AddSponsor",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    ValidationError,
    BaseInput,
    BackButton,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [],
      allTimezones: [],
      sponsor: {
        type: "sponsors",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: "Australia",
        categories: [],
        sponsor_categories: [],
        summary: "",
        email: "",
        label: "",
        created_at: "2024-04-11 09:21:08",
        updated_at: "2024-04-11 09:21:10",
      },
      showAddModal: false,
     
    };
  },
   computed: {
    profileImage() {
      if (!_.isEmpty(this.sponsor.profile_image)) {
        if (
          this.sponsor.profile_image.includes("http://") ||
          this.sponsor.profile_image.includes("https://")
        ) {
          return this.sponsor.profile_image;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.sponsor.profile_image;
      }
      return (
        process.env.VUE_APP_BASE_URL +
        "/img/brand/bmis-default-asset-placeholder.png"
      );
    },
  },
  created() {
    this.auth();
    this.allTimezones = moment.tz.names();
    this.getCategories();
  },

  methods: {
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar")[0].focus();
      }, 10);
    },
    async auth() {
      try {
        // Fetch User data
        // await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Sponsors" });
    },
    redirect(sponsor) {
      this.$router.push({
        name: "Edit Sponsor",
        params: { id: sponsor.id },
      });
    },
    async onSubmit() {
        this.loading = true;
     try {
        await this.$store.dispatch("sponsors/add", this.sponsor);
        this.sponsor = await this.$store.getters["sponsors/sponsor"];
        this.loading = false;
        this.$notify({
            type: "success",
            message: "Sponsor added successfully."
        });
        this.redirect(this.sponsor);
     } catch (error) {
        this.loading = false;
        this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.sponsor.id) {
          await this.$store.dispatch("sponsors/destroy", this.sponsor.id);
        }
     }  
    },
    uploadAssetUrl(resource, previousImage = null) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "/uploads/sponsors/" +
        this.$route.params.id +
        "/" +
        resource +
        "?previousImg=" +
        previousImage
      );
    },
    async getCategories() {
    try{
      await this.$store.dispatch("categories/list");
      this.allCategories = this.$store.getters["categories/dropdown"];
    } catch (error){
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
  }
  },
};
</script>