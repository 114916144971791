<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
        <div class="row justify-content-center mt-3">
          <div class="col-xl-6 order-xl-1">
            <transition name="el-zoom-in-center">
              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-md-8">
                    <h3 class="mb-0">Set up the profile of the sponsor</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Name *"
                      name="Name"
                      type="text"
                      placeholder="name of the sponsor"
                      v-model="sponsor.name"
                      required
                    />
                    <validation-error :errors="apiValidationErrors.name" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Label  *"
                      name="Label"
                      type="text"
                      placeholder="label of the sponsor"
                      v-model="sponsor.label"
                      required
                    />
                    <validation-error :errors="apiValidationErrors.label" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Email *"
                      name="Email"
                      type="text"
                      placeholder="email of the sponsor"
                      v-model="sponsor.email"
                      required
                    />
                    <validation-error :errors="apiValidationErrors.name" />
                  </div>
                </div>

                
                <base-input
                  type="text"
                  label="Summary *"
                  success-message="With bio you can go into a bit more detail about your background."
                >
                  <textarea
                    v-model="sponsor.summary"
                    placeholder="With bio you can go into a bit more detail about your background."
                    class="form-control"
                    rows="3"
                    name="Summary"
                    required
                  >
                  </textarea>
                </base-input>
                <validation-error :errors="apiValidationErrors.summary" />
              </card>
            </transition>
          </div>
          <div class="col-xl-6 order-xl-1">
            <card header-classes="bg-transparent" name="details">
              <div slot="header" class="row align-items-center">
                <div class="col-md-8">
                  <h3 class="mb-0">Provide more details</h3>
                </div>
              </div>
              <div class="card-body">
                <base-input label="Available for">
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.virtual"
                    :description="{
                      show: true,
                      content: 'Virtual events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.physical"
                    :description="{
                      show: true,
                      content: 'In-person events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="sponsor.hybrid"
                    :description="{
                      show: true,
                      content: 'Hybrid events',
                    }"
                  ></base-switch>
                </base-input>

                <base-input label="Categories">
                  <el-select
                    v-model="sponsor.sponsor_categories"
                    multiple
                    placeholder="Select..."
                  >
                    <el-option
                      v-for="single_category in allCategories"
                      :key="single_category.id"
                      :value="single_category.id"
                      :label="single_category.name"
                    >
                    </el-option>
                  </el-select>
                </base-input>

                <template>
                  <label class="form-control-label">Logo </label>

                  <div
                    class="card bg-dark text-white border-0"
                    v-show="!actions.uploadProfile"
                  >
                    <img
                      class="card-img"
                      :src="profileImage"
                      alt="Speaker Profile Image"
                    />
                    <div class="card-img-overlay d-flex align-items-center">
                      <div>
                        <base-button
                          type="primary"
                          @click="actions.uploadProfile = true"
                        >
                          <i class="fa fa-upload mr-2" />Upload new
                          Image</base-button
                        >
                      </div>
                    </div>
                  </div>

                  <base-file-input
                    help-message="<i class='fa fa-upload'></i> Select your best Profile to upload! </br> <small class='filepond--label-action text-muted'>Drop or Browse files</small></br> "
                    :endpoint="uploadAssetUrl('logo', sponsor.logo)"
                    preview-height="400"
                    v-show="actions.uploadProfile"
                  />
                </template>
              </div>
            </card>
            <!-- Sticky buttons -->
            <div id="sticky-buttons" class="my-4 text-right">
              <card type="frame">
                <div class="mt--3 mb--3">
                  <base-button
                    type="button"
                    class="btn btn-primary"
                    v-if="!loading"
                    data-testid="btn-create-item"
                    native-type="submit"
                  >
                    <i class="fa fa-save" /> Update sponsor
                  </base-button>
                  <div class="text-center" v-if="loading">
                    <slot name="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                    </slot>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import moment from "moment-timezone";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option, Tabs, TabPane } from "element-ui";
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BackButton from "@/components/BackButton.vue";
import BaseFileInput from "@/components/Files/BaseFileInput";

export default {
  name: "EditSponsor",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    ValidationError,
    BaseInput,
    BackButton,
    BaseFileInput,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [],
      allTimezones: [],
      sponsor: {
        type: "sponsors",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: "Australia",
        categories: [],
        sponsor_categories: [],
        summary: "",
        email: "",
        label: "",
        created_at: "2024-04-11 09:21:08",
        updated_at: "2024-04-11 09:21:10",
      },
      showAddModal: false,
      actions: {
        uploadProfile: false,
      },
    };
  },
  computed: {
    profileImage() {
      if (!_.isEmpty(this.sponsor.logo)) {
        if (
          this.sponsor.logo.includes("http://") ||
          this.sponsor.logo.includes("https://")
        ) {
          return this.sponsor.logo;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.sponsor.logo;
      }
      return (
        process.env.VUE_APP_BASE_URL +
        "/img/brand/bmis-default-asset-placeholder.png"
      );
    },
  },
  created() {
    this.auth();
    this.getCategories();
    this.allTimezones = moment.tz.names();
    this.getSponsor();
  },
  methods: {
    async getSponsor() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        let params = {
          filter: {
            ...(this.user
              ? { contractor_id: this.user.id }
              : { contractor_id: 0 }),
          },
          include: "categories",
        };
        await this.$store
          .dispatch("sponsors/get", { id, params })
          .then(() => (this.sponsor = this.$store.getters["sponsors/sponsor"]))
          .then(() => {
             this.sponsor.sponsor_categories = this.sponsor?.categories?.map(
              (obj) => obj.id
            );
          })
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  

  customFocus() {
    setTimeout(function () {
      document.getElementsByClassName("flatpickr-calendar")[0].focus();
    }, 10);
  },
  async auth() {
    try {
      // Fetch User data
      // await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
    } catch (error) {
      // Notification
      this.$notify({
        type: "danger",
        message: "Oops, something went wrong with your account!",
      });
      this.setApiValidation(error.response.data.errors);
    }
  },
  goBack() {
    this.$router.push({ name: "List Sponsors" });
  },
  redirect(sponsor) {
    this.$router.push({
      name: "Sponsor Preview",
      params: { id: sponsor.id },
    });
  },
  async onSubmit(values) {
    this.loading = true;
    try {
      await this.$store.dispatch("sponsors/update", this.sponsor);
      this.sponsor = await this.$store.getters["sponsors/sponsor"];
      this.loading = false;
      this.$notify({
        type: "success",
        message: "Sponsor updated successfully.",
      });
      this.redirect(this.sponsor);
    } catch (error) {
      this.loading = false;
      this.$notify({
        type: "danger",
        message: "Oops, something went wrong!",
      });
      this.setApiValidation(error.response.data.errors);
      if (this.sponsor.id) {
        await this.$store.dispatch("sponsors/destroy", this.sponsor.id);
      }
    }
  },
  uploadAssetUrl(resource, logo = null) {
    return (
      process.env.VUE_APP_API_BASE_URL +
      "/uploads/sponsors/" +
      this.$route.params.id +
      "/" +
      resource +
      "?logo=" +
      logo
    );
  },
  async getCategories() {
    try{
      await this.$store.dispatch("categories/list");
      this.allCategories = this.$store.getters["categories/dropdown"];
    } catch (error){
      this.$notify({
        type: "danger",
        message: `Oops, something went wrong!`,
      });
      this.setApiValidation(error.response.data.errors);
    }
  }
  },
};
</script>